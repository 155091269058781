<template>
  <v-col cols="12">
    <h4>Select Project And Enter Links</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
              v-model="detail.project_id"
              :items="projectsFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              deletable-chips
              chips
              small-chips
              hide-details
              label="Select Project (min. 3 characters required)"
              @keyup="projectsFilterKeyUp"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.development && data.item.development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                    <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              clearable
              outlined
              dense
              hide-details
              label="Enter URL"
              color="primary"
              maxlength="200"
              :counter="200"
              v-model="detail.url">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
// import {bus} from "@/main";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";

export default {
  name: 'share-project-details',
  data() {
    return {
      projectsFilter: [],
      details: [
        {project_id: '', url: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$emit('spdUpdated', v)
      },
      deep: true
    }
  },
  mounted() {
    this.details = [
      {project_id: '', url: ''}
    ]
    // bus.$on("contactCardDetailsDeletedByModal", () => {
    //   this.details = [
    //     {project_id: '', url: ''}
    //   ]
    // });
  },
  methods: {
    add() {
      this.details.push({project_id: '', url: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
    projectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: 'none'
              })
              .then(data => {
                that.projectsFilter.push(...data.records.projectsList);
              });
        }
      }, 900);
    },
  }
}
</script>