<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="offerDialog" fullscreen>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="offerDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Select And Offer Listings To This Lead</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="editedItem.listing_ids.length === 0"
                          dark
                          text
                          @click="saveItem"
                      >
                        Done
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <Listings offerListings="true" @offerListingsItem="getOfferListingsItems" />
                </v-card>
              </v-dialog>
              <div class="d-flex">
                <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                  <i style="color: #fff" class="flaticon2-refresh-1"></i>
                </v-btn>
                <v-btn
                    v-if="items.length > 0"
                    color="#00004d"
                    dark
                    class="ma-3 ml-0 mr-1"
                    @click="sendOLEmail(lead.contact_emails)"
                >
                  Send Email
                  <v-icon small right>fa fa-envelope</v-icon>
                </v-btn>
                <v-btn color="#00004d" dark class="ma-3 ml-0" @click="offerDialog = true">
                  Offer Listings To This Lead
                </v-btn>
              </div>
              <v-col cols="12">
                <v-card-title>
                  Offered Listings ({{ addTSBVZ(totalRecords) }})
                </v-card-title>
                <v-row>
                  <v-col cols="12">
                    <v-pagination
                        color="#00004d"
                        :total-visible="15"
                        @input="refreshData(false)"
                        :disabled="loading"
                        v-model="pagination.page"
                        :length="pageCount">
                    </v-pagination>
                  </v-col>
                </v-row>
                <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
                >
                </v-skeleton-loader>
                <v-card-text v-if="!isLoaded">
                  <v-row v-if="items.length > 0">
                    <v-col cols="12" v-for="(item, index) in items" :key="index">
                      <v-card
                          class="mx-auto"
                          outlined
                          raised
                      >
                        <v-toolbar
                            height="40"
                            dark
                            color="#00004d"
                        >
                          <!--                          <v-checkbox class="mt-5"-->
                          <!--                                      :true-value="1"-->
                          <!--                                      :false-value="0"-->
                          <!--                                      :value="item"-->
                          <!--                                      v-model="mainDataTableSelection"></v-checkbox>-->
                          <v-toolbar-title class="hidden-sm-and-down" v-if="item.location_ref === 'project' && !item.location_ref_bp">
                            <span v-html="item.purpose ? item.purpose.title : ''"></span>
                            <v-icon v-if="item.purpose" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span v-html="item.country ? item.country.title : ''"></span>
                            <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span v-html="item.city ? item.city.title : ''"></span>
                            <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                            <span
                                v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
                                dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
                                dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
                                dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(item.project && item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.project && item.project.development && item.project.development.parent_development"
                                dark
                                x-small
                                class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="item.project && item.project.development ? item.project.development.commercial_title : ''"></span>
                            <v-icon v-if="item.project && item.project.development" dark x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span v-html="item.project && item.project.title"></span>
                            <v-icon v-if="item.unit_no && item.project" dark x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span v-html="item.unit_no"></span>
                          </v-toolbar-title>
                          <v-toolbar-title class="hidden-sm-and-down" v-if="item.location_ref === 'development' && !item.location_ref_bp">
                            <span v-html="item.purpose ? item.purpose.title : ''"></span>
                            <v-icon v-if="item.purpose" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span v-html="item.country ? item.country.title : ''"></span>
                            <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span v-html="item.city ? item.city.title : ''"></span>
                            <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                            <span
                                v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                                dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                                dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                                dark
                                x-small
                                class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="item.development && item.development.parent_development ? item.development.parent_development.commercial_title : ''"></span>
                            <v-icon v-if="item.development && item.development.parent_development" dark x-small
                                    class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span v-html="item.development && item.development.commercial_title"></span>
                            <v-icon v-if="item.view_o_and_u === 1 && item.unit_no && item.development" dark x-small
                                    class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span v-if="item.view_o_and_u === 1" v-html="item.unit_no"></span>
                          </v-toolbar-title>
                          <v-toolbar-title class="hidden-sm-and-down" v-if="item.location_ref_bp">
                            <span v-html="item.location_ref_bp"></span>
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-toolbar-items class="hidden-sm-and-down">
<!--                            <v-btn-->
<!--                                dark-->
<!--                                text-->
<!--                                @click="showEditDialog('detail', item)"-->
<!--                            >-->
<!--                              View Details-->
<!--                            </v-btn>-->
<!--                            <v-btn-->
<!--                                dark-->
<!--                                text-->
<!--                                @click="deleteItem(item)"-->
<!--                            >-->
<!--                              Remove-->
<!--                            </v-btn>-->
                          </v-toolbar-items>
<!--                          <v-menu-->
<!--                              bottom-->
<!--                              :close-on-content-click="true"-->
<!--                          >-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <v-btn-->
<!--                                  class="hidden-md-and-up"-->
<!--                                  height="25"-->
<!--                                  color="primary"-->
<!--                                  dark-->
<!--                                  v-bind="attrs"-->
<!--                                  v-on="on"-->
<!--                              >-->
<!--                                Actions-->
<!--                              </v-btn>-->
<!--                            </template>-->

<!--&lt;!&ndash;                            <v-list>&ndash;&gt;-->
<!--&lt;!&ndash;                              <v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                                <v-btn&ndash;&gt;-->
<!--&lt;!&ndash;                                    text&ndash;&gt;-->
<!--&lt;!&ndash;                                    @click="deleteItem(item)"&ndash;&gt;-->
<!--&lt;!&ndash;                                >&ndash;&gt;-->
<!--&lt;!&ndash;                                  Remove&ndash;&gt;-->
<!--&lt;!&ndash;                                </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;                              </v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                            </v-list>&ndash;&gt;-->
<!--                          </v-menu>-->
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="4">
                              <h6>Title</h6>
                              <p class="hidden-sm-and-down" v-html="item.title"></p>
                              <p class="hidden-md-and-up" v-if="item.location_ref === 'project' && !item.location_ref_bp">
                                <span v-html="item.purpose ? item.purpose.title : ''"></span>
                                <v-icon v-if="item.purpose" x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span v-html="item.country ? item.country.title : ''"></span>
                                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span v-html="item.city ? item.city.title : ''"></span>
                                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                                <span
                                    v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
                                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
                                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
                                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="(item.project && item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.project && item.project.development && item.project.development.parent_development"
                                    x-small
                                    class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="item.project && item.project.development ? item.project.development.commercial_title : ''"></span>
                                <v-icon v-if="item.project && item.project.development" x-small class="ml-1 mr-1">fas
                                  fa-angle-double-right
                                </v-icon>

                                <span v-html="item.project && item.project.title"></span>
                                <v-icon v-if="item.unit_no && item.project" x-small class="ml-1 mr-1">fas
                                  fa-angle-double-right
                                </v-icon>

                                <span v-html="item.unit_no"></span>
                              </p>
                              <p class="hidden-md-and-up" v-if="item.location_ref === 'development' && !item.location_ref_bp">
                                <span v-html="item.purpose ? item.purpose.title : ''"></span>
                                <v-icon v-if="item.purpose" x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span v-html="item.country ? item.country.title : ''"></span>
                                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span v-html="item.city ? item.city.title : ''"></span>
                                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                                <span
                                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                                <v-icon
                                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                                    x-small
                                    class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span
                                    v-html="item.development && item.development.parent_development ? item.development.parent_development.commercial_title : ''"></span>
                                <v-icon v-if="item.development && item.development.parent_development" x-small
                                        class="ml-1 mr-1">fas
                                  fa-angle-double-right
                                </v-icon>

                                <span v-html="item.development && item.development.commercial_title"></span>
                                <v-icon v-if="item.view_o_and_u === 1 && item.unit_no && item.development" x-small
                                        class="ml-1 mr-1">fas fa-angle-double-right
                                </v-icon>

                                <span v-if="item.view_o_and_u === 1" v-html="item.unit_no"></span>
                              </p>
                              <p class="hidden-md-and-up" v-if="item.location_ref_bp">
                                <span v-html="item.location_ref_bp"></span>
                              </p>
                              <h6>Purpose</h6>
                              <p v-html="item.purpose ? item.purpose.title : ''"></p>
                              <h6>Property Type</h6>
                              <h6 v-html="item.property_type ? item.property_type.title : ''"></h6>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <div v-if="item.purpose_id === 2">
                                <h6>Rent</h6>
                                <p class="font-weight-bold"
                                   v-html="item.max_rent_per_annum ? item.max_rent_per_annum : '-'"></p>
                              </div>
                              <div v-else>
                                <h6>Sale Price</h6>
                                <p class="font-weight-bold"
                                   v-html="item.max_sale_price ? item.max_sale_price : '-'"></p>
                              </div>
                              <h6>Number Of Bedrooms</h6>
                              <p v-html="item.no_of_bedrooms ? item.no_of_bedrooms : '-'"></p>
                              <h6>Number Of Bathrooms</h6>
                              <p v-html="item.no_of_bathrooms ? item.no_of_bathrooms : '-'"></p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <h6>Publishing Status</h6>
                              <p v-html="item.publishing_status ? item.publishing_status.title : ''"></p>
                              <h6>Completion Status</h6>
                              <p v-html="item.completion_status ? item.completion_status.title : ''"></p>
                              <h6>Offered By:</h6>
                              <p v-html="item.pivot_user + ' At ' + formatDateAndTime(item.pivot.created_at)"></p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <p class="text-center"><i>No Record Found.</i></p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    dense
                    fixed-header
                    height="500"
                    :options.sync="pagination"
                    :server-items-length="totalRecords"
                    :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                    :loading="loading"
                    v-show="!isLoaded"
                    mobile-breakpoint="100"
                    class="elevation-0 custom-datatable">
                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                        class="custom-datatable-footer5"
                        :pagination="pagination"
                        :options="options"
                        @update:options="updateOptions"
                        :items-per-page-options="rowsPerPageItems"
                        :show-first-last-page="true"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                  </template>
                  <template v-slot:item.title="{ item }">
                    <div class="text-truncate" v-if="item.location_ref === 'project'">
                      <span v-html="item.purpose ? item.purpose.title : ''"></span>
                      <v-icon v-if="item.purpose" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                      <span v-html="item.country ? item.country.title : ''"></span>
                      <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                      <span v-html="item.city ? item.city.title : ''"></span>
                      <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                      <span
                          v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.project && item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(item.project && item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.project && item.project.development && item.project.development.parent_development"
                          x-small
                          class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="item.project && item.project.development ? item.project.development.commercial_title : ''"></span>
                      <v-icon v-if="item.project && item.project.development" x-small class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span v-html="item.project && item.project.title"></span>
                      <v-icon v-if="item.project" x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span v-html="item.unit_no"></span>
                    </div>

                    <div class="text-truncate" v-if="item.location_ref === 'development'">
                      <span v-html="item.purpose ? item.purpose.title : ''"></span>
                      <v-icon v-if="item.purpose" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                      <span v-html="item.country ? item.country.title : ''"></span>
                      <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                      <span v-html="item.city ? item.city.title : ''"></span>
                      <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                      <span
                          v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                          x-small
                          class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="item.development && item.development.parent_development ? item.development.parent_development.commercial_title : ''"></span>
                      <v-icon v-if="item.development && item.development.parent_development" x-small
                              class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span v-html="item.development && item.development.commercial_title"></span>
                      <v-icon v-if="item.development" x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span v-html="item.unit_no"></span>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div class="text-truncate">
                      <v-btn color="red" small @click="deleteItem(item)" dark class="mr-2">
                        <v-icon dark x-small>fas fa-trash</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
                <v-row>
                  <v-col cols="12">
                    <v-pagination
                        color="#00004d"
                        :total-visible="15"
                        @input="refreshData(false)"
                        :disabled="loading"
                        v-model="pagination.page"
                        :length="pageCount">
                    </v-pagination>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  OFFERED_LISTINGS_LIST,
  SAVE_OFFERED_LISTINGS,
  DELETE_OFFERED_LISTING,
  SEND_OFFERED_LISTINGS_EMAIL,
  CLEAR_LEADS_ERRORS
} from "@/core/services/store/leads.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import Listings from "@/view/pages/realstate/listings/Listings";
import {LISTINGS_SIMPLE_LIST} from "@/core/services/store/listings.module";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

export default {
  name: 'offered-listings',
  components: {Listings},
  props: ['lead'],
  data() {
    return {
      that: this,
      headers: [
        {text: "Listing", value: "title", name: "title", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      listingsFilter: [],
      isListingsLoaded: false,
      locationRef: 'Project',
      items: [],
      dialog: false,
      editedItem: {listing_ids: []},
      offerDialog: false,
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_LEADS_ERRORS);
      if (!val) this.editedItem = {listing_ids: []};
    },
    lead() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    offerDialog(val) {
      !val && this.$store.dispatch(CLEAR_LEADS_ERRORS);
      if (!val) this.editedItem = {listing_ids: []};
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(OFFERED_LISTINGS_LIST, {
          lead_id: this.lead.id,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        // this.getLeadMatchingListings();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem() {
      let method = SAVE_OFFERED_LISTINGS;
      let data = {
        lead_id: this.lead.id,
        listing_ids: this.editedItem.listing_ids,
      };
      this.$store.dispatch(CLEAR_LEADS_ERRORS);
      this.$store.dispatch(method, {data: data}).then(() => {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
        this.offerDialog = false
      })
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete?", clr: 'red', callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_OFFERED_LISTING, {
            'lead_id': that.lead.id,
            'listing_id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    listingsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isListingsLoaded = true;
          that.$store
              .dispatch(LISTINGS_SIMPLE_LIST, {
                type: 'all',
                refId: e.target.value
              })
              .then(data => {
                that.listingsFilter.push(...data.records.listingsList);
                that.isListingsLoaded = false;
              });
        }
      }, 900);
    },
    getLeadMatchingListings() {
      let that = this
      that.isListingsLoaded = true;
      that.$store
          .dispatch(LISTINGS_SIMPLE_LIST, {
            type: 'lead_matching',
            refId: that.lead.id
          })
          .then(data => {
            that.listingsFilter = data.records.listingsList;
            that.isListingsLoaded = false;
          });
    },
    sendOLEmail(emails) {
      if (emails !== '') {
        let that = this;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "You want to send offered listings email to " + this.lead.contact_emails + "?",
          clr: 'primary',
          callback: function () {
            this.$store.dispatch(SEND_OFFERED_LISTINGS_EMAIL, {
              'lead_id': that.lead.id,
            })
            //     .then(() => {
            //   that.loadData().then(response => {
            //     that.items = response.items;
            //     that.totalRecords = response.total;
            //   });
            // });
          }
        });
      } else {
        this.$store.dispatch(SET_SNACK, {clr: 'error', msg: "No email(s) found."});
      }
    },
    getOfferListingsItems(v) {
      this.editedItem.listing_ids = v
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.leads.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>