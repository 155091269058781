import {
    SHARED_LEADS_LIST,
} from "@/core/services/store/leads.module";
import {mapState} from "vuex";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {INQUIRYSTATUSES_SIMPLE_LIST} from "@/core/services/store/inquiry_statuses.module";
import {STAFF_LIST} from "@/core/services/store/users.module";
import LeadComments from "@/view/pages/realstate/leads/inc/LeadComments";
import OfferedListings from "@/view/pages/realstate/leads/inc/OfferedListings";
import {bus} from "@/main";

const sharedLeadsScript = {
    name: 'shared-leads',
    components: {LeadComments,OfferedListings},
    props: ['searchFormData', 'advSHitted'],
    data() {
        return {
            headers: [
                {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: false},
                {
                    text: "Created",
                    value: "comment_updated_at",
                    name: "comment_updated_at",
                    filterable: false,
                    sortable: true
                },
                {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
                {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
            ],
            draw: 1,
            searchQuery: "",
            loading: true,
            pagination: {
                page: 1,
                rowsPerPage: 25,
                totalItems: 0,
                sortDesc: "desc"
            },
            totalRecords: 0,
            rowsPerPageItems: [25, 50, 100, 150, 200],
            headerindex: "",
            inquiryStatusesFilter: [],
            viewsFilter: [],
            viewsIdsFilter: [],
            isLoaded: true,
            contactsFilter: [],
            isLoadedContacts: false,
            projectsFilter: [],
            isProjectsLoaded: false,
            staffFilter: [],
            columns: [],
            items: [],
            dialog: false,
            editedItem: {},
            purposesFilter: [],
            listingsFilter: [],
            isListingsLoaded: false,
            developmentsFilter: [],
            isDevelopmentsLoaded: false,
            builtUpAreaUnit: '',
            plotAreaUnit: '',
            leadRD: false,
            leadRR: '',
            leadRI: {},
        }
    },
    watch: {
        // params: {
        //     handler() {
        //         this.loadData().then(response => {
        //             this.items = response.items;
        //             this.totalRecords = response.total;
        //         });
        //     },
        //     deep: true
        // },
        dupDialog(val) {
            if (!val) this.dupItem = '';
            if (!val) this.duplicatedItem = {p_id: '', p_type_id: ''}
        },
        leadRD(val) {
            if (!val) this.leadRI = {};
            if (!val) this.leadRR = '';
        },
        advSHitted: {
            handler() {
                this.refreshData()
            },
            deep: true
        },
    },
    mounted() {
        for (var i = 0; i < this.headers.length; i++) {
            this.columns.push(
                '&columns[' + i + '][data]=' + this.headers[i].value +
                '&columns[' + i + '][name]=' + this.headers[i].name +
                '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
                '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
                '&columns[' + i + '][search][value]=' +
                '&columns[' + i + '][search][regex]=' + false
            );
        }
        this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
            this.getInquiryStatuses();
        });
        bus.$on('leadUpdatedByParent', res => {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
                this.getInquiryStatuses();
            });
        });
    },
    methods: {
        getHeaderIndex(name) {
            let that = this;
            this.headers.filter(function (ele, i) {
                if (name == ele.name) {
                    that.headerindex = i;
                }
            });
        },
        loadData() {
            this.loading = true;
            this.isLoaded = true;
            return new Promise((resolve) => {
                let params = this.params;
                params.length = params.itemsPerPage; //set how many records to fetch per page
                params.start =
                    params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
                params.draw = this.draw;
                let query = Object.keys(params).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&');
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                this.getHeaderIndex(params.sortBy[0]);
                query += '&' + searchFormDataQuery
                query +=
                    "&search[value]=" +
                    params.query +
                    "&search[regex]=" +
                    false +
                    "&order[0][column]=" +
                    (params.sortBy.length === 0 ? 1 : this.headerindex) +
                    "&order[0][dir]=" +
                    (params.sortDesc[0] ? "desc" : "asc");
                this.$store.dispatch(SHARED_LEADS_LIST, {q: query, columns: this.columns}).then((res) => {
                    this.draw++;
                    let items = res.records.data;
                    let total = res.records.recordsFiltered;
                    this.loading = !this.loading;
                    this.isLoaded = !this.isLoaded;
                    resolve({
                        items,
                        total
                    });
                });
            });
        },
        refreshData(loadDep = true) {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
                if (loadDep) this.getInquiryStatuses();
            });
        },
        getInquiryStatuses(loadNext = true) {
            this.$store.dispatch(INQUIRYSTATUSES_SIMPLE_LIST).then((data) => {
                this.inquiryStatusesFilter = data.records.inquiryStatusesList;
                if (loadNext) this.getPurposes()
            });
        },
        getStaff() {
            this.$store.dispatch(STAFF_LIST).then((data) => {
                this.staffFilter = data.records.staffList;
            });
        },
        saveLeadRR() {
            let that = this;
            this.$store.dispatch(CLEAR_LEADS_ERRORS);
            let data = {
                reason: this.leadRR,
                lead_id: this.leadRI.id,
                status: 0
            };
            this.$store.dispatch(UPDATE_LEADS_STATUS, {data: data})
                .then(() => {
                    that.leadRD = false
                    that.loadData().then(response => {
                        that.items = response.items;
                        that.totalRecords = response.total;
                    });
                });
        },
        refreshComments(comment, item) {
            item.lead_comments = [comment, ...item.lead_comments]
            // item.contact_comments.push(comment);
            item.comment_count++;
        },
        leadStatusesChange(evt, item){
            let that = this;
            if (evt === 1) {
                this.$store.dispatch(SET_CONFIRMATION, {
                    title: "Are you sure?",
                    msg: "You want to accept?",
                    clr: 'success',
                    callback: function () {
                        let id = item.id;
                        let sts = evt;
                        this.$store.dispatch(CLEAR_LEADS_ERRORS);
                        let data = {
                            lead_id: id,
                            status: sts
                        };
                        this.$store.dispatch(UPDATE_LEADS_STATUS, {data: data})
                        .then(() => {
                            that.loadData().then(response => {
                                that.items = response.items;
                                that.totalRecords = response.total;
                            });
                        });
                    }
                });
            } else {
                this.leadRD = true;
                this.leadRI = item
            }
        }
    },
    computed: {
        params() {
            return {
                ...this.pagination,
                query: this.searchQuery
            };
        },
        ...mapState({
            errors: state => state.leads.errors
        }),
        pageCount() {
            // return this.totalRecords / this.pagination.rowsPerPage
            return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
        }
    }
};
export default sharedLeadsScript