<template>
  <div class="comments-section">
    <v-row>
      <v-col cols="12">
        <div class="form-group" v-for="(comment,k) in comments" :key="k" style="margin: 0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded outlined raised
                      v-bind="attrs"
                      v-on="on"
              >
                <!--            <v-card-title v-html="comment.created_user_name ? comment.created_user_name : '-&#45;&#45;'"></v-card-title>-->
                <!--            <v-card-subtitle v-html="formatDateAndTime(comment.created_at)"></v-card-subtitle>-->
                <v-card-text>
                  <div class="text--primary font-weight-bold" v-if="comment.inquiry_status" v-html="comment.inquiry_status.title"></div>
                  <div class="text--primary" v-html="comment.comment ? replaceNewLine(comment.comment) : ''"></div>
                </v-card-text>
              </v-card>
              <br>
            </template>
            <span
                v-html="comment.created_user_name ? comment.created_user_name + ' at ' + formatDateAndTime(comment.created_at) : '-'"></span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="lead.comment_count !== comments.length">
      <v-col cols="12">
        <a
            href="javascript:void(0)"
            :style="commentLoading ? 'pointer-events: none' : 'pointer-events: all'" @click="getComments">Show previous comments</a>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-autocomplete
            v-model="titleId"
            :items="commentTitles"
            item-text="title"
            item-value="id"
            dense
            clearable
            outlined
            hide-details
            chips
            small-chips
            label="Status"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-textarea
            outlined
            clearable
            dense
            hide-details
            label="Write Comment"
            color="primary"
            v-model="comment"
            maxlength="500"
            :counter="500">
        </v-textarea>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-btn :disabled="!comment" @click="saveComment" class="theme-btn" dark style="width: 100%">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {SAVE_LEAD_COMMENT, GET_LEAD_COMMENT, CLEAR_LEADS_ERRORS} from "@/core/services/store/leads.module";

export default {
  name: 'lead-comments',
  props: ['lead','leadCommentsOld', 'commentTitles'],
  data() {
    return {
      page: 1,
      comment: '',
      titleId: '',
      comments: [],
      commentLoading: false
    }
  },
  watch: {
    leadCommentsOld(v) {
      this.comments = v;
    }
  },
  mounted() {
    this.comments = this.leadCommentsOld
  },
  methods: {
    saveComment() {
      this.$store.dispatch(CLEAR_LEADS_ERRORS);
      this.$store.dispatch(SAVE_LEAD_COMMENT, {'lead_id': this.lead.id,
        comment: this.comment, inquiry_status_id: this.titleId}).then((res) => {
        this.$emit('commentSaved', res.records);
        this.comment = ''
        this.titleId = ''
        this.page = 1;
      })
    },
    getComments() {
      this.commentLoading = true
      this.$store.dispatch(GET_LEAD_COMMENT, {'lead_id': this.lead.id, page: this.page}).then((res) => {
        let that = this;
        if (res.records.data.length > 0){
          if (this.page === 1) {
            this.comments = res.records.data;
          } else {
            res.records.data.forEach(function (v){
              that.comments.push(v)
            })
          }
          this.page++
          this.commentLoading = false
        }
        // this.$emit('commentSaved');
      })
    }
  }
}
</script>