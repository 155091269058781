import {
    LEADS_LIST,
    CREATE_LEADS,
    UPDATE_LEADS,
    CLEAR_LEADS_ERRORS,
    UPDATE_LEADS_STATUS,
    DELETE_LEADS,
    GET_LEADS_DETAILS, SHARE_LEADS,
} from "@/core/services/store/leads.module";
import {mapState} from "vuex";
import ViewsSelection from "@/view/pages/realstate/selections/ViewsSelection";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {INQUIRYSTATUSES_SIMPLE_LIST} from "@/core/services/store/inquiry_statuses.module";
import {CONTACTS_SIMPLE_LIST, VERIFY_EMAILS} from "@/core/services/store/contacts.module";
import {STAFF_LIST} from "@/core/services/store/users.module";
import {PROJECTS_SIMPLE_LIST, PROJECTS_SIMPLE_BY_IDS_LIST} from "@/core/services/store/projects.module";
import {
    DEVELOPMENTS_SIMPLE_LIST,
    DEVELOPMENTS_SIMPLE_BY_IDS_LIST,
    DEVELOPMENTS_WITH_COUNT
} from "@/core/services/store/developments.module";
import {LISTINGS_SIMPLE_LIST, LISTINGS_SIMPLE_BY_IDS_LIST} from "@/core/services/store/listings.module";
import LeadComments from "@/view/pages/realstate/leads/inc/LeadComments";
import AssignedLeads from "@/view/pages/realstate/leads/inc/AssignedLeads";
import SharedLeads from "@/view/pages/realstate/leads/inc/SharedLeads";
import OfferedListings from "@/view/pages/realstate/leads/inc/OfferedListings";
import {PURPOSES_SIMPLE_LIST} from "@/core/services/store/purposes.module";
import {VIEWS_SIMPLE_LIST} from "@/core/services/store/views.module";
import {bus} from "@/main";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import {SOURCES_SIMPLE_LIST} from "@/core/services/store/sources.module";
import SendEmail from "@/view/pages/realstate/contacts_management/inc/SendEmail";

const listingsScript = {
    name: 'leads',
    components: {LeadComments,OfferedListings,ViewsSelection, AssignedLeads, SharedLeads, SendEmail},
    data() {
        return {
            headers: [
                {text: 'Purpose', value: 'purpose.title', name: 'purpose.title', filterable: false, sortable: false},
                {text: 'Beds', value: 'no_of_bedrooms', name: 'no_of_bedrooms', filterable: false, sortable: false},
                {text: 'Bathroom', value: 'no_of_bathrooms', name: 'no_of_bathrooms', filterable: false, sortable: false},
                {text: 'Location', value: 'location', name: 'location', filterable: false, sortable: false},
                {text: 'Built Up Area', value: 'built_up_area', name: 'built_up_area', filterable: false, sortable: false},
                {text: 'Plot Area', value: 'plot_area', name: 'plot_area', filterable: false, sortable: false},
                {text: 'Min Budget', value: 'min_budget', name: 'min_budget', filterable: false, sortable: false},
                {text: 'Max Budget', value: 'max_budget', name: 'max_budget', filterable: false, sortable: false},
                {text: "Created", align: ' d-none', value: "comment_updated_at", name: "comment_updated_at", filterable: false, sortable: true},
                {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
            ],
            draw: 1,
            searchQuery: "",
            loading: true,
            pagination: {
                page: 1,
                rowsPerPage: 25,
                totalItems: 0,
                sortDesc: "desc"
            },
            totalRecords: 0,
            rowsPerPageItems: [25, 50, 100, 150, 200],
            headerindex: "",
            inquiryStatusesFilter: [],
            viewsFilter: [],
            viewsIdsFilter: [],
            isLoaded: true,
            contactsFilter: [],
            mortgageBanksFilter: [],
            isLoadedContacts: false,
            isLoadedMBContacts: false,
            projectsFilter: [],
            isProjectsLoaded: false,
            staffFilter: [],
            columns: [],
            validationRules: {
                valid: true,
                titleRules: [
                    v => !!v || "This field is required",
                    v => (v && v.length >= 3) || "Must be greater than 3 characters",
                    v => (v && v.length <= 200) || "Must be less than 200 characters"
                ],
                selectRule: [
                    (v) => {
                        if (!v || v.length < 1)
                            return 'Select an option';
                        else return true;
                    }
                ],
                multipleRules: [
                    v => {
                        if (!v || v.length < 1) return "Select at least one option";
                        else return true;
                    }
                ]
            },
            items: [],
            dialog: false,
            editedItem: {},
            purposesFilter: [],
            sourcesFilter: [],
            listingsFilter: [],
            isListingsLoaded: false,
            searchFormData: {},
            developmentsFilter: [],
            isDevelopmentsLoaded: false,
            builtUpAreaUnit: '',
            plotAreaUnit: '',
            typesOfLead: [
                {value: 'dev', title: "Master/Sub Development"},
                {value: 'pro', title: "Project"},
                {value: 'lis', title: "Listing"}
            ],
            advSHitted: false,
            shareDialog: false,
            leadSharingIds: [],
            treeDialog: false,
            treeItems: [],
            emailDialog: false,
            emailMsg: '',
            vEmails: [],
        }
    },
    watch: {
        // params: {
        //     handler() {
        //         this.loadData().then(response => {
        //             this.items = response.items;
        //             this.totalRecords = response.total;
        //         });
        //     },
        //     deep: true
        // },
        dupDialog(val) {
            if (!val) this.dupItem = '';
            if (!val) this.duplicatedItem = {p_id: '', p_type_id: ''}
        },
        dialog(val) {
            !val && this.$store.dispatch(CLEAR_LEADS_ERRORS);
            if (!val) this.editedItem = {};
            if (!val) this.$store.state.selections.selectedViewIds = []
            if (val) {
                this.builtUpAreaUnit = this.builtUpAreaUnit ? this.builtUpAreaUnit : this.$store.state.auth.user.measurement_unit
                this.plotAreaUnit = this.plotAreaUnit ? this.plotAreaUnit : this.$store.state.auth.user.measurement_unit
            } else {
                this.builtUpAreaUnit = ''
                this.plotAreaUnit = ''
            }
        },
        shareDialog(val) {
            if (val) {
                const index = this.staffFilter.findIndex(element => {
                    if (element.id === this.$store.state.auth.user.id) {
                        return true;
                    }
                });
                if (index !== -1) this.staffFilter.splice(index, 1);
            }
            if (!val) this.leadSharingIds = [];
        }
    },
    mounted() {
        for (var i = 0; i < this.headers.length; i++) {
            this.columns.push(
                '&columns[' + i + '][data]=' + this.headers[i].value +
                '&columns[' + i + '][name]=' + this.headers[i].name +
                '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
                '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
                '&columns[' + i + '][search][value]=' +
                '&columns[' + i + '][search][regex]=' + false
            );
        }
        if (this.$route.query.hasOwnProperty('rel')) {
            this.activeTabInner = 1
        }
        if (this.$route.query.hasOwnProperty('tol') && this.$route.query.hasOwnProperty('refs')) {
            if (this.$route.query.tol === 'listings') {
                this.editedItem.type_of_lead = 'lis'
                if (this.$route.query.refs) {
                    this.getListingsByIds(this.$route.query.refs.split(','))
                }
            } else if (this.$route.query.tol === 'developments') {
                this.editedItem.type_of_lead = 'dev'
                if (this.$route.query.refs) {
                    this.getDevelopmentsByIds(this.$route.query.refs.split(','))
                }
            } else if (this.$route.query.tol === 'projects') {
                this.editedItem.type_of_lead = 'pro'
                if (this.$route.query.refs) {
                    this.getProjectsByIds(this.$route.query.refs.split(','))
                }
            } else {
                this.editedItem.type_of_lead = null
            }
        }
        this.showTree(0, 'all');
        this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
            this.getInquiryStatuses();
        });
    },
    methods: {
        getHeaderIndex(name) {
            let that = this;
            this.headers.filter(function (ele, i) {
                if (name == ele.name) {
                    that.headerindex = i;
                }
            });
        },
        loadData() {
            this.loading = true;
            this.isLoaded = true;
            return new Promise((resolve) => {
                let params = this.params;
                params.length = params.itemsPerPage; //set how many records to fetch per page
                params.start =
                    params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
                params.draw = this.draw;
                let query = Object.keys(params).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&');
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                this.getHeaderIndex(params.sortBy[0]);
                query += '&' + searchFormDataQuery
                query +=
                    "&search[value]=" +
                    params.query +
                    "&search[regex]=" +
                    false +
                    "&order[0][column]=" +
                    (params.sortBy.length === 0 ? 8 : this.headerindex) +
                    "&order[0][dir]=" +
                    (params.sortDesc[0] ? "desc" : "asc");
                this.$store.dispatch(LEADS_LIST, {q: query, columns: this.columns}).then((res) => {
                    // this.draw++;
                    let items = res.records.data;
                    let total = res.records.recordsFiltered;
                    this.loading = !this.loading;
                    this.isLoaded = !this.isLoaded;
                    resolve({
                        items,
                        total
                    });
                });
            });
        },
        refreshData(loadDep = true) {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
                this.advSHitted = !this.advSHitted
                if (loadDep) this.getInquiryStatuses();
            });
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        hitAdvanceSearch(reset = false) {
            this.pagination.page = 1
            this.advSHitted = !this.advSHitted;
            if (reset) {
                this.searchFormData = {};
                this.showTree(0, 'all');
            }
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        },
        showEditDialog(item) {
            this.$store.dispatch(GET_LEADS_DETAILS, item.id).then((data) => {
                this.editedItem = data.records || {};
                if (data.records.contact) {
                    this.contactsFilter = [data.records.contact];
                }
                if (data.records.mortgage_bank) {
                    this.mortgageBanksFilter = [data.records.mortgage_bank];
                }
                if (data.records.listings.length > 0) {
                    this.editedItem.type_of_lead = 'lis'
                    this.listingsFilter = data.records.listings;
                }
                if (data.records.developments.length > 0) {
                    this.editedItem.type_of_lead = 'dev'
                    this.developmentsFilter = data.records.developments;
                }
                if (data.records.projects.length > 0) {
                    this.editedItem.type_of_lead = 'pro'
                    this.projectsFilter = data.records.projects;
                }
                this.builtUpAreaUnit = data.records.built_up_area_unit
                this.plotAreaUnit = data.records.plot_area_unit
                this.editedItem.min_price = this.addTSBV(data.records.min_price)
                this.editedItem.max_price = this.addTSBV(data.records.max_price)
                this.editedItem.min_rent = this.addTSBV(data.records.min_rent)
                this.editedItem.max_rent = this.addTSBV(data.records.max_rent)
                this.editedItem.built_up_area = this.addTSBV(data.records.built_up_area)
                this.editedItem.plot_area = this.addTSBV(data.records.plot_area)
                this.editedItem.contact_budget = this.addTSBV(data.records.contact_budget)
                this.editedItem.pre_approved_amount = this.addTSBV(data.records.pre_approved_amount)
                this.$store.state.selections.selectedViewIds = this.editedItem.view_ids
                // this.staffFilter = data.records.users;
                this.dialog = !this.dialog;
            });
        },
        closeDialog() {
            this.dialog = !this.dialog;
        },
        saveItem(item) {
            if (this.$refs.form.validate()) {
                let method = CREATE_LEADS;
                let id = item.id;
                let data = {
                    data: item
                };
                item.min_price = item.min_price ? item.min_price.replace(/,(?=\d{3})/g, '') : ''
                item.max_price = item.max_price ? item.max_price.replace(/,(?=\d{3})/g, '') : ''
                item.min_rent = item.min_rent ? item.min_rent.replace(/,(?=\d{3})/g, '') : ''
                item.max_rent = item.max_rent ? item.max_rent.replace(/,(?=\d{3})/g, '') : ''
                item.built_up_area = item.max_rent ? item.built_up_area.replace(/,(?=\d{3})/g, '') : ''
                item.plot_area = item.max_rent ? item.plot_area.replace(/,(?=\d{3})/g, '') : ''
                item.pre_approved_amount = item.pre_approved_amount ? item.pre_approved_amount.replace(/,(?=\d{3})/g, '') : ''
                item.contact_budget = item.contact_budget ? item.contact_budget.replace(/,(?=\d{3})/g, '') : ''
                this.$set(item, "built_up_area_unit", this.builtUpAreaUnit);
                this.$set(item, "plot_area_unit", this.plotAreaUnit);
                this.$set(item, "view_ids", this.$store.state.selections.selectedViewIds);
                if (id) {
                    method = UPDATE_LEADS;
                }
                this.$store.dispatch(CLEAR_LEADS_ERRORS);
                this.$store.dispatch(method, {'slug': id, data: data.data}).then((res) => {
                    this.loadData().then(response => {
                        this.items = response.items;
                        this.totalRecords = response.total;
                    });
                    this.dialog = !this.dialog;
                    bus.$emit("leadUpdatedByParent", res);
                })
            }
        },
        deleteItem(item) {
            let that = this;
            this.$store.dispatch(SET_CONFIRMATION, {
                title: "Are you sure?",
                msg: "You want to archive?",
                clr: 'red',
                callback: function () {
                    let id = item.id;
                    this.$store.dispatch(DELETE_LEADS, {
                        'id': id
                    }).then(() => {
                        that.loadData().then(response => {
                            that.items = response.items;
                            that.totalRecords = response.total;
                        });
                    });
                }
            });
        },
        getInquiryStatuses(loadNext = true) {
            this.$store.dispatch(INQUIRYSTATUSES_SIMPLE_LIST).then((data) => {
                this.inquiryStatusesFilter = data.records.inquiryStatusesList;
                if (loadNext) this.getSources()
            });
        },
        getSources(loadNext = true) {
            this.$store.dispatch(SOURCES_SIMPLE_LIST).then((data) => {
                this.sourcesFilter = data.records.sourcesList;
                if (loadNext) this.getPurposes()
            });
        },
        getPurposes(loadNext = true) {
            this.$store.dispatch(PURPOSES_SIMPLE_LIST).then((data) => {
                this.purposesFilter = data.records.purposesList;
                if (loadNext) this.getViews()
            });
        },
        getViews(loadNext = true) {
            this.$store.dispatch(VIEWS_SIMPLE_LIST, {type: 'Properties'}).then((data) => {
                this.viewsFilter = data.records.viewsList;
                let that = this;
                this.viewsFilter.forEach(function (v) {
                    if (v.children.length > 0) {
                        v.children.forEach(function (vv) {
                            that.viewsIdsFilter.push(vv.id)
                        })
                    }
                })
                if (loadNext) this.getStaff()
            });
        },
        getStaff() {
            this.$store.dispatch(STAFF_LIST).then((data) => {
                this.staffFilter = data.records.staffList;
            });
        },
        developmentsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isDevelopmentsLoaded = true;
                    that.$store
                        .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                            q: e.target.value
                        })
                        .then(data => {
                            that.developmentsFilter.push(...data.records.developmentsList);
                            that.isDevelopmentsLoaded = false;
                        });
                }
            }, 900);
        },
        projectsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isProjectsLoaded = true;
                    that.$store
                        .dispatch(PROJECTS_SIMPLE_LIST, {
                            q: e.target.value,
                            type: 'none'
                        })
                        .then(data => {
                            that.projectsFilter.push(...data.records.projectsList);
                            that.isProjectsLoaded = false;
                        });
                }
            }, 900);
        },
        listingsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isListingsLoaded = true;
                    that.$store
                        .dispatch(LISTINGS_SIMPLE_LIST, {
                            type: 'all',
                            refId: e.target.value
                        })
                        .then(data => {
                            that.listingsFilter.push(...data.records.listingsList);
                            that.isListingsLoaded = false;
                        });
                }
            }, 900);
        },
        contactsFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isLoadedContacts = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'All',
                            q: e.target.value
                        })
                        .then(data => {
                            that.contactsFilter = that.contactsFilter.concat(data.records.contactsList);
                            that.isLoadedContacts = false;
                        });
                }
            }, 900);
        },
        mortgageBanksFilterKeyUp(e) {
            clearTimeout(this.apiTimeout)
            let that = this
            this.apiTimeout = setTimeout(function () {
                if (e.target.value.length >= 3) {
                    that.isLoadedMBContacts = true;
                    that.$store
                        .dispatch(CONTACTS_SIMPLE_LIST, {
                            role: 'UAE Banks',
                            q: e.target.value
                        })
                        .then(data => {
                            that.mortgageBanksFilter = that.mortgageBanksFilter.concat(data.records.contactsList);
                            that.isLoadedMBContacts = false;
                        });
                }
            }, 900);
        },
        updateStatus(status, id, column) {
            this.$store.dispatch(CLEAR_LEADS_ERRORS);
            let data = {
                status: status,
                column: column
            };
            this.$store.dispatch(UPDATE_LEADS_STATUS, {'slug': id, data: data})
        },
        refreshComments(comment, item) {
            item.lead_comments = [comment, ...item.lead_comments]
            // item.contact_comments.push(comment);
            item.comment_count++;
        },
        getListingsByIds(ids) {
            this.$store.dispatch(LISTINGS_SIMPLE_BY_IDS_LIST, {'ids': ids})
                .then((data) => {
                    this.listingsFilter = data.records.listingsList;
                }).then(() => {
                this.dialog = true
            }).then(() => {
                this.editedItem.listing_ids = this.listingsFilter.map((el) => el.id)
                this.editedItem.purpose_id = this.listingsFilter[0].purpose_id
                this.editedItem.source_id = this.listingsFilter[0].source_id
                this.editedItem.plot_area = this.listingsFilter[0].plot_area
                this.editedItem.plot_area_length = this.listingsFilter[0].plot_area_length
                this.editedItem.plot_area_width = this.listingsFilter[0].plot_area_width
                this.editedItem.plot_area_unit = this.listingsFilter[0].plot_area_unit
                this.editedItem.built_up_area = this.listingsFilter[0].built_up_area
                this.editedItem.built_up_area_length = this.listingsFilter[0].built_up_area_length
                this.editedItem.built_up_area_width = this.listingsFilter[0].built_up_area_width
                this.editedItem.built_up_area_unit = this.listingsFilter[0].built_up_area_unit
                this.editedItem.no_of_bedrooms = this.listingsFilter[0].no_of_bedrooms
                this.editedItem.no_of_bathrooms = this.listingsFilter[0].no_of_bathrooms
                this.editedItem.min_price = this.listingsFilter[0].min_sale_price ? this.addTSBV(this.listingsFilter[0].min_sale_price) : ''
                this.editedItem.max_price = this.listingsFilter[0].max_sale_price ? this.addTSBV(this.listingsFilter[0].max_sale_price) : ''
                this.editedItem.min_rent = this.listingsFilter[0].min_rent_per_annum ? this.addTSBV(this.listingsFilter[0].min_rent_per_annum) : ''
                this.editedItem.max_rent = this.listingsFilter[0].max_rent_per_annum ? this.addTSBV(this.listingsFilter[0].max_rent_per_annum) : ''
            });
        },
        getDevelopmentsByIds(ids) {
            this.$store.dispatch(DEVELOPMENTS_SIMPLE_BY_IDS_LIST, {'ids': ids})
                .then((data) => {
                    this.developmentsFilter = data.records.developmentsList;
                }).then(() => {
                this.dialog = true
            }).then(() => {
                this.editedItem.development_ids = this.developmentsFilter.map((el) => el.id)
            });
        },
        getProjectsByIds(ids) {
            this.$store.dispatch(PROJECTS_SIMPLE_BY_IDS_LIST, {'ids': ids})
                .then((data) => {
                    this.projectsFilter = data.records.projectsList;
                }).then(() => {
                this.dialog = true
            }).then(() => {
                this.editedItem.project_ids = this.projectsFilter.map((el) => el.id)
            });
        },
        updateSharedLeads(item) {
            this.showEditDialog(item)
        },
        showShareDialog() {
            this.shareDialog = true;
            this.leadSharingIds = this.$store.state.auth.user.lead_sharing_ids
        },
        shareLeads() {
            this.$store.dispatch(CLEAR_LEADS_ERRORS);
            this.$store.dispatch(SHARE_LEADS, {'user_ids': this.leadSharingIds}).then(() => {
                this.$store.dispatch(VERIFY_AUTH)
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                this.shareDialog = false
            })
        },
        showTree(id, type) {
            this.pagination.page = 1
            if (id !== 0) {
                if (type === 'project') {
                    this.searchFormData.projectSearch = id
                } else {
                    this.searchFormData.developmentSearch = id
                }
                this.loadData().then(response => {
                    this.items = response.items;
                    this.totalRecords = response.total;
                    this.scrollToElement();
                });
            }
            if (type === 'development' || type === 'all') {
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                this.$store.dispatch(DEVELOPMENTS_WITH_COUNT, {type: 'leads', 'id': id, 'get': type, query: searchFormDataQuery})
                    .then((data) => {
                        this.treeItems = data.records.developmentsList
                    })
            }
        },
        scrollToElement() {
            const el = this.$refs.scrollTo;
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        },
        verifyEmails() {
            this.$store.dispatch(VERIFY_EMAILS, {contactIds: this.mainDataTableSelection}).then((data) => {
                this.mainDataTableSelection = []
                this.emailDialog = true
                this.vEmails = data.records.emails
            })
        }
    },
    computed: {
        params() {
            return {
                ...this.pagination,
                query: this.searchQuery
            };
        },
        ...mapState({
            errors: state => state.leads.errors
        }),
        pageCount() {
            // return this.totalRecords / this.pagination.rowsPerPage
            return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
        }
    }
};
export default listingsScript