<template>
  <v-col cols="12">
    <h4>Select Developments And Enter Links</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
              v-model="detail.development_id"
              :items="developmentsFilter"
              item-text="commercial_title"
              item-value="id"
              clearable
              outlined
              dense
              deletable-chips
              chips
              small-chips
              hide-details
              label="Select Development (min. 3 characters required)"
              @keyup="developmentsFilterKeyUp"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.parent_development && data.item.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="data.item.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              clearable
              outlined
              dense
              hide-details
              label="Enter URL"
              color="primary"
              maxlength="200"
              :counter="200"
              v-model="detail.url">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
// import {bus} from "@/main";
import {DEVELOPMENTS_SIMPLE_LIST} from "@/core/services/store/developments.module";

export default {
  name: 'share-development-details',
  data() {
    return {
      developmentsFilter: [],
      details: [
        {development_id: '', url: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$emit('sddUpdated', v)
      },
      deep: true
    }
  },
  mounted() {
    this.details = [
      {development_id: '', url: ''}
    ]
    // bus.$on("contactCardDetailsDeletedByModal", () => {
    //   this.details = [
    //     {development_id: '', url: ''}
    //   ]
    // });
  },
  methods: {
    add() {
      this.details.push({development_id: '', url: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.$store
              .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                q: e.target.value
              })
              .then(data => {
                that.developmentsFilter.push(...data.records.developmentsList);
              });
        }
      }, 900);
    },
  }
}
</script>