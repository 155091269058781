<template>
  <v-card>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeEmailDlg')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span v-html="'Send Email'"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
            :disabled="!validationRules.valid"
            dark
            text
            @click="sendEmail"
        >
          Send
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-col cols="12" class="pb-0" v-if="errors!=undefined && errors.length > 0">
      <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger m-0"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>
    </v-col>
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-form
              v-model="validationRules.valid"
              lazy-validation
              ref="email-form"
          >
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                    return-object
                    outlined
                    dense
                    v-model="emails"
                    :items="contactEmails"
                    item-text="email"
                    item-value="id"
                    label="Send To*"
                    chips
                    multiple
                    small-chips
                    deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field outlined
                              dense
                              clearable
                              v-model="mEmails"
                              maxLength="300"
                              :counter="300"
                              label="Enter More Emails Here Separated By ','(comma) Sign">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field outlined
                              dense
                              clearable
                              v-model="subject"
                              maxLength="100"
                              :counter="100"
                              label="Subject">
                </v-text-field>
              </v-col>
<!--              <v-col cols="12" sm="6">-->
<!--                <v-text-field outlined-->
<!--                              dense-->
<!--                              clearable-->
<!--                              v-model="replyTo"-->
<!--                              maxLength="100"-->
<!--                              :counter="100"-->
<!--                              label="Reply To Email">-->
<!--                </v-text-field>-->
<!--              </v-col>-->
              <v-col cols="12" sm="4">
                <v-select
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                    v-model="emailSharingType"
                    :items="emailSharingTypesFilters"
                    label="Share"
                    @change="developments = []; projects = []; listings = []"
                ></v-select>
              </v-col>
              <ShareDevelopmentDetails @sddUpdated="sddUpdated" v-if="emailSharingType === 1"/>
              <ShareProjectDetails @spdUpdated="spdUpdated" v-if="emailSharingType === 2"/>
              <ShareListingDetails :sharedListings="sharedListings" @sldUpdated="sldUpdated" v-if="emailSharingType === 3"/>
              <v-col cols="12" sm="4">
                <v-select
                    dense
                    outlined
                    :items="[{title: 'List Your Property Template', value: '1'}]"
                    v-model="eTemplate"
                    @change="tChange"
                    item-text="title"
                    item-value="value"
                    label="Template"
                    clearable
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <h5>Message</h5>
                <br>
<!--                <quill-editor-->
<!--                    style="height: 300px"-->
<!--                    v-model="message"-->
<!--                />-->
                <editor
                    :api-key="tinyKey"
                    ref="rc_editor"
                    v-model="message"
                    :init="tinyEditorInit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {CLEAR_CONTACT_ERRORS, SEND_EMAIL} from "@/core/services/store/contacts.module";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import ShareDevelopmentDetails from "@/view/pages/realstate/contacts_management/inc/ShareDevelopmentDetails";
import ShareProjectDetails from "@/view/pages/realstate/contacts_management/inc/ShareProjectDetails";
import ShareListingDetails from "@/view/pages/realstate/contacts_management/inc/ShareListingDetails";

export default {
  name: 'send-email',
  components: {ShareListingDetails, ShareProjectDetails, ShareDevelopmentDetails},
  props: ['contactEmails', 'emailMsg', 'sharedListings'],
  data() {
    return {
      emails: [],
      developments: [],
      projects: [],
      listings: [],
      mEmails: '',
      subject: '',
      replyTo: '',
      emailSharingType: 0,
      message: '',
      eTemplate: '',
      validationRules: {
        valid: true,
        msgRule: [
          v => !!v || "This field is required",
          v => (v && v.length <= 500) || "Must be less than or equal to 500 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select at least one email";
            else return true;
          }
        ]
      },
    }
  },
  watch: {
    contactEmails() {
      this.developments = []
      this.projects = []
      this.listings = []
      this.emailSharingType = ''
      this.mEmails = ''
      this.subject = ''
      this.replyTo = ''
      this.eTemplate = ''
      this.emails = this.contactEmails
      this.message = this.emailMsg
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
    },
    sharedListings: {
      handler(v) {
        this.listings = v
        this.emailSharingType = 3
      },
      deep: true
    }
  },
  mounted() {
    this.emails = this.contactEmails
    this.message = this.emailMsg
    if (this.sharedListings.length > 0) {
      this.listings = this.sharedListings
      this.emailSharingType = 3
    }
  },
  methods: {
    sendEmail() {
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      this.$store.dispatch(SEND_EMAIL, {
        emails: this.emails,
        mEmails: this.mEmails,
        subject: this.subject,
        replyTo: this.replyTo,
        message: this.message,
        type: this.emailSharingType,
        developments: this.developments.length > 0 ? this.developments : 0,
        projects: this.projects.length > 0 ? this.projects : 0,
        listings: this.listings.length > 0 ? this.listings : 0,
      }).then(() => {
        this.$emit('closeEmailDlg');
        this.item = {}
        this.$store.dispatch(VERIFY_AUTH)
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      })
    },
    sddUpdated(v) {
      this.developments = v
    },
    spdUpdated(v) {
      this.projects = v
    },
    sldUpdated(v) {
      this.listings = v
    },
    tChange(v) {
      if (v === '1') {
        this.message = this.$store.state.auth.user.type === 1 ? this.lypTemplate : this.lypAfsTemplate
      } else {
        this.message = ''
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.contacts.errors
    })
  }
};
</script>